import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currency: string = 'USD';
  rate: number = 1;
  listCurrency: string[] = ['USD', 'VND', 'EUR', 'INR', 'AUD'];
  private keySubject = new BehaviorSubject<string | null>(null);

  constructor() {
    this.updateCurrency();
  }

  updateCurrency() {
    let currencyConversationData = JSON.parse(
      localStorage.getItem('currency-conversation-data') || '{}'
    );
    if (currencyConversationData.currency && this.listCurrency.includes(currencyConversationData.currency)) {
      this.currency = currencyConversationData.currency;
      this.rate = currencyConversationData.rate;
    }
  }

  receiveSignalChangeCurrency(): Observable<any> {
    return this.keySubject.asObservable();
  }

  sendSignalChangeCurrency() {
    this.keySubject.next('change');
  }
}
